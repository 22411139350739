import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import usePipelineStatistics from '@/graphql/hooks/clients/usePipelineStatistics';
import useClientId from '@/hooks/router/useClientId';
import { Category } from '@/types/statistics/category';
import usePipelineStatisticsDetails from '@/graphql/hooks/clients/usePipelineStatisticsDetails';
import useMiniMissions from '@/graphql/hooks/searchPoolJobs/useMiniMissions';
import InfoTooltip from '@/components/Common/InfoTooltip';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import EllipsisMenu from '@/components/EllipsisMenu';
import {
  DisplayType,
  PipelineCountDataPointForCategory,
  PipelineDetailDataPointForCategory,
} from '../../components/PipelineStatisticsByCategory/types';
import PipelineStatisticsByCategory from '../../components/PipelineStatisticsByCategory';
import { TimeSpan } from '../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';

import styles from './PipelineStatisticsByProject.module.less';
import { segments } from '../../components/PipelineStatisticsByCategory/helpers/segments';
import { defaultPipelineDescriptor } from '../../components/PipelineStatisticsByCategory/defaultPipelineDescriptor';

interface PipelineStatisticsByProjectProps {
  timeSpan: TimeSpan;
  missionIds: string[];
  withInterestedSubSteps?: boolean;
}

const PipelineStatisticsByProject: React.FC<
  PipelineStatisticsByProjectProps
> = ({ missionIds, timeSpan, withInterestedSubSteps }) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [displayType, setDisplayType] = useState<DisplayType>('ever');
  const [countArchived, setCountArchived] = useState(false);

  const { loading: statsLoading, volumeByPipeline = [] } =
    usePipelineStatistics({
      clientId,
      ...(withInterestedSubSteps && {
        withInterestedSubSteps: true,
      }),
      startDate: timeSpan.startDate.format('YYYY-MM-DD'),
      endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    });
  const { loading: miniMissionsLoading, miniMissions } = useMiniMissions();
  const [
    fetchDetails,
    { loading: detailsLoading, volumeByPipeline: detailsVolumeByPipeline },
  ] = usePipelineStatisticsDetails();

  const missionFilter = useCallback(
    ({ missionId }: { missionId: string }) => {
      if (!_.isEmpty(missionIds) && !_.contains(missionIds, missionId)) {
        return false;
      }
      if (countArchived) {
        return true;
      }
      const miniMission = _.findWhere(miniMissions || [], { id: missionId });
      return !miniMission?.isArchived;
    },
    [miniMissions, missionIds, countArchived],
  );

  const filteredVolumeByPipeline = useMemo(
    () => _.filter(volumeByPipeline, missionFilter),
    [volumeByPipeline, missionFilter],
  );

  const formattedData: PipelineCountDataPointForCategory[] = useMemo(
    () =>
      _.map(filteredVolumeByPipeline, ({ missionId, data }) => ({
        categoryId: missionId,
        data,
      })),
    [filteredVolumeByPipeline],
  );

  const formattedDetailsData: PipelineDetailDataPointForCategory[] = useMemo(
    () =>
      _.chain(detailsVolumeByPipeline)
        .filter(missionFilter)
        .map(({ missionId, data }) => ({
          missionId,
          categoryId: missionId,
          data,
        }))
        .value(),
    [detailsVolumeByPipeline, missionFilter],
  );

  const categories: Category[] = useMemo(
    () =>
      _.map(filteredVolumeByPipeline, ({ missionId }) => {
        const miniMission = _.findWhere(miniMissions || [], { id: missionId });
        return {
          id: missionId,
          title: miniMission?.data?.title || '???',
          link: `/client/${clientId}/reveal/projects/recruiting/${missionId}`,
        };
      }),
    [filteredVolumeByPipeline, clientId, miniMissions],
  );

  const displayTypeSwitchOptions = [
    {
      key: 'ever',
      text: t('reveal.reports.missions.pipelineDisplayType.ever'),
      value: 'ever',
    },
    {
      key: 'current',
      text: t('reveal.reports.missions.pipelineDisplayType.current'),
      value: 'current',
    },
  ];

  const onChangeDisplayType = (value: DisplayType) => {
    setDisplayType(value);
  };

  const handleExportData = useCallback(() => {
    const data = formattedData.map(({ categoryId, data: d }) => ({
      projectId: categoryId,
      data: d,
    }));
    const csvData = [
      [
        '',
        ...segments.map((stage) => t(`reveal.pipelineSegmentations.${stage}`)),
      ],
      [
        'Total',
        ...segments.map((stage) => {
          return _.reduce(
            data,
            (sum, { data: item }) => {
              if (stage === 'archived') {
                return sum + item.filter(({ archived }) => archived)?.length;
              }
              const stageData = item.find(({ state: s }) => s === stage);
              return sum + (stageData?.count || 0);
            },
            0,
          );
        }),
      ],
      [],
      ...data.map(({ projectId, data: item }) => [
        `"${categories.find(({ id }) => id === projectId)?.title || projectId}"`,
        ...segments.map((stage) => {
          if (stage === 'archived') {
            return item.filter(({ archived }) => archived)?.length || 0;
          }
          const stageData = item.find(({ state: s }) => s === stage);
          return stageData?.count || 0;
        }),
      ]),
    ];
    const csvContent = `${encodeURI('data:text/csv;charset=utf-8,')}${encodeURIComponent(
      csvData.map((row) => row.join(',')).join('\r\n'),
    )}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute(
      'download',
      `pipeline_statistics_by_project_${timeSpan.startDate.format(
        'YYYY-MM-DD',
      )}_${timeSpan.endDate.format('YYYY-MM-DD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [formattedData, categories, t, timeSpan]);

  const pipelineDescriptor = useMemo(() => {
    if (withInterestedSubSteps) {
      if (
        clientId === 'bloomays' ||
        clientId === 'bloomays-d06' ||
        clientId === 'acme'
      ) {
        return {
          steps: [
            {
              id: 'pending',
              title: { default: 'Not contacted', fr: 'Non contactés' },
            },
            {
              id: 'in-progress',
              title: { default: 'Contacted', fr: 'Contactés' },
            },
            { id: 'replied', title: { default: 'Replied', fr: 'Répondus' } },
            { id: 'interested_5rdmzs', title: { default: 'Préq.' } },
            { id: 'interested_o8qw4o', title: { default: 'Qual.' } },
            { id: 'interested_4tu6cy', title: { default: 'Pos.' } },
            { id: 'interested_wi537y', title: { default: 'Prépa ITW' } },
            { id: 'interested_22f6zi', title: { default: 'Debrief' } },
            { id: 'interested_9crue5', title: { default: 'Valid. Info' } },
            { id: 'interested_x7662l', title: { default: 'BPA' } },
            { id: 'hired', title: { default: 'Hired', fr: 'Recrutés' } },
            { id: 'archived', title: { default: 'Archivés' } },
          ],
        };
      }
      if (clientId === 'hirestone') {
        return {
          steps: [
            {
              id: 'pending',
              title: { default: 'Not contacted', fr: 'Non contactés' },
            },
            {
              id: 'in-progress',
              title: { default: 'Contacted', fr: 'Contactés' },
            },
            { id: 'replied', title: { default: 'Replied', fr: 'Répondus' } },
            { id: 'interested_x36rtf', title: { default: 'CV Envoyé' } },
            { id: 'interested_up8r52', title: { default: 'ITW 1' } },
            { id: 'interested_5ij8mb', title: { default: 'ITW 2' } },
            { id: 'interested_w9zxz8', title: { default: 'ITW 3' } },
            { id: 'interested_rvfj4w', title: { default: 'Test Tech' } },
            { id: 'interested_9wneju', title: { default: 'Final' } },
            { id: 'hired', title: { default: 'Hired', fr: 'Recrutés' } },
            { id: 'archived', title: { default: 'Archivés' } },
          ],
        };
      }
      if (clientId === 'taylor-river') {
        return {
          steps: [
            {
              id: 'pending',
              title: { default: 'Not contacted', fr: 'Non contactés' },
            },
            {
              id: 'in-progress',
              title: { default: 'Contacted', fr: 'Contactés' },
            },
            { id: 'replied', title: { default: 'Replied', fr: 'Répondus' } },
            { id: 'interested_3wqnug', title: { default: 'TEL' } },
            { id: 'interested_02zlc6', title: { default: 'INTR' } },
            { id: 'interested_ifkfyv', title: { default: 'ECV' } },
            { id: 'interested_rdgjng', title: { default: 'ICL1' } },
            { id: 'interested_3rwxgx', title: { default: 'ICLN' } },
            { id: 'interested_0r4hw8', title: { default: 'REF' } },
            { id: 'interested_pxdt10', title: { default: 'OFFRE' } },
            { id: 'hired', title: { default: 'Hired', fr: 'Recrutés' } },
            { id: 'archived', title: { default: 'Archivés' } },
          ],
        };
      }
    }
    return defaultPipelineDescriptor;
  }, [clientId, withInterestedSubSteps]);

  return (
    <div className={styles.volumeByStage}>
      <div className={styles.top}>
        <h3>
          {t('reveal.reports.missions.pipelineStats')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>{t('reveal.reports.tooltips.missions.pipeline.title')}</h1>
            <h2>
              {t('reveal.reports.tooltips.common.pipeline.passthroughRates')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.common.pipeline.passthroughRatesParagraph',
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.paragraph`,
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
            <p>
              {t('reveal.reports.tooltips.common.pipeline.manualMoveParagraph')}
            </p>
            <p>
              {t('reveal.reports.tooltips.common.pipeline.deletedParagraph')}
            </p>
            <h2>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.alternativeTitle`,
              )}
            </h2>
            <p>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.alternativeParagraph`,
              )}
            </p>
          </InfoTooltip>
        </h3>
        <div className={styles.topRight}>
          <LabeledCheckbox
            checked={countArchived}
            onClick={() => setCountArchived(!countArchived)}
            label={t('reveal.missions.pipeline.countArchived')}
          />
          <Dropdown
            selection
            selectOnBlur={false}
            options={displayTypeSwitchOptions}
            defaultValue='ever'
            onChange={(_e, { value }) =>
              onChangeDisplayType(value as DisplayType)
            }
            className='hiresweet-rounded'
          />
          {!withInterestedSubSteps ? (
            <EllipsisMenu
              direction='left'
              items={[
                {
                  key: 'export',
                  title: t('reveal.missions.pipeline.exportAsCSV'),
                  onClick: () => handleExportData(),
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      {statsLoading || miniMissionsLoading ? (
        <div className={styles.loader}>
          <Loader active inline='centered' size='large' />
        </div>
      ) : (
        <PipelineStatisticsByCategory
          categorization={t('reveal.reports.missions.projectName')}
          categories={categories}
          data={formattedData}
          pipelineDescriptor={pipelineDescriptor}
          displayType={displayType}
          detailsLoading={detailsLoading}
          detailsData={formattedDetailsData}
          fetchDetails={({ categoryIds, stages, archivedStates }) => {
            fetchDetails({
              clientId,
              stagesFilter: !_.isEmpty(stages) ? { in: stages } : undefined,
              archivedStatusesFilter: !_.isEmpty(archivedStates)
                ? { in: archivedStates }
                : undefined,
              missionsFilter: !_.isEmpty(categoryIds)
                ? { in: categoryIds }
                : undefined,
              startDate: timeSpan.startDate.format('YYYY-MM-DD'),
              endDate: timeSpan.endDate.format('YYYY-MM-DD'),
            });
          }}
        />
      )}
    </div>
  );
};

export default PipelineStatisticsByProject;
